import http from "../http-common";
import authHeader from "./Auth-header";

class CostDataService {
  getCeco(data) {
      return http.post("/gasto/ceco/", data )
  }
  getCompras(data) {
      return http.post('/compras', data, {headers:authHeader()})
  }
  getSPC(data) {
    return http.post('/spc', data, {headers:authHeader()})
  }
  getDescodigo(data){
      return http.post('/codigo', data, {headers:authHeader()})
  }

  upFiles(file, spc, fileProgress) {
    let user = JSON.parse(sessionStorage.getItem('user'));
    let formData= new FormData()
    formData.append('file', file)
    formData.append('spc', spc)

    return http.post('/upfile', formData,  {
      headers: {
        "Content-Type": "multipart/form-data",
        'x-access-token': user.accessToken
      },
      fileProgress
    });
  }

  newSPC(data) {
    return http.post('/gasto/newspc', data, {headers:authHeader()})
  }

  getProveedores (data) {
      return http.post('/gasto/proveedor', data, {headers:authHeader()})
  }
}

export default new CostDataService();